import React from 'react';

import classes from './Spinner.module.css';

const Spinner = () => <div className={ classes.loader }>
  <div className={ classes.side }/>
  <div className={ classes.side }/>
  <div className={ classes.side }/>
  <div className={ classes.side }/>
  <div className={ classes.side }/>
  <div className={ classes.side }/>
  <div className={ classes.side }/>
  <div className={ classes.side }/>
</div>;

export default Spinner;
