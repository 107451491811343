import { Spinner } from 'react-bootstrap';
import React from 'react';

import classes from './ButtonSpinner.module.css';

const ButtonSpinner = () => <Spinner
  as='span'
  className={ classes.buttonSpinner }
  animation='border'
  size='sm'
  role='status'
  aria-hidden='true'/>;

export default ButtonSpinner;
