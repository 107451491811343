import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

import { LoaderConsumer } from '../Loader/LoaderContext';

function withLoader(Component) {
  const displayName = `withLoader(${ Component.displayName || Component.name })`;
  const WrappedComponent = props => {
    const { wrappedComponentRef, ...remainingProps } = props;

    return (
      <LoaderConsumer>
        {
          ({ showLoader, hideLoader, updateProgress }) => {
          return (
            <Component
              { ...remainingProps }
              showLoader={ showLoader }
              hideLoader={ hideLoader }
              updateProgress={ updateProgress }
              ref={ wrappedComponentRef }
            />
          );
        }
        }
      </LoaderConsumer>
    );
  };

  WrappedComponent.displayName = displayName;
  WrappedComponent.WrappedComponent = Component;

  return hoistStatics(WrappedComponent, Component);
}

export default withLoader;
