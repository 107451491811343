import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RowContext from './RowContext/RowContext';
import TableBody from './TableBody/TableBody';
import TableHead from './TableHead/TableHead';
import PaginationControl from './PaginationControl/PaginationControl';

import classes from './LiteTable.module.css';
import AlignmentEnum from './AlignmentEnum';

class LiteTable extends Component {
  state = {
    rows: [],
    sorting: {
      column: null,
      reversed: false
    },
    pagination: {
      page: 1,
      perPage: 50,
      total: 0
    },
    filtering: {},
    checking: {
      checkedRows: [],
      areAllRowsChecked: false,
      checkboxKey: 'id'
    }
  };

  componentDidMount() {
    const initialState = { ...this.state };

    initialState.sorting = this.props.initialSorting ?? initialState.sorting;
    initialState.filtering = this.props.initialFiltering ?? initialState.filtering;
    initialState.pagination = this.props.initialPagination ?? initialState.pagination;
    initialState.checking = this.props.initialChecking ?? initialState.checking;
    initialState.checking.checkboxKey = this.props.checkboxKey ?? initialState.checking.checkboxKey;
    initialState.rows = this.filter(this.props.rows, initialState.filtering);

    this.setState(initialState);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.rows !== prevProps.rows) {
      this.setState({ rows: this.filter(this.props.rows, this.state.filtering) });
    }
  }

  rowTogglingHandler(value) {
    let checkedRows = [ ...this.state.checking.checkedRows ];
    const index = checkedRows.indexOf(value);
    if (index === -1) {
      checkedRows.push(value);
    } else {
      delete checkedRows[index];
      checkedRows = checkedRows.filter(item => item !== undefined);
    }

    this.checkBoxes(checkedRows, this.getSelectableRows().length === checkedRows.length);
  }

  allRowsTogglingHandler() {
    let checkedRows = [];
    let areAllRowsChecked = false;
    if (!this.state.checking.areAllRowsChecked) {
      this.getSelectableRows().forEach(value => {
        checkedRows.push(value[this.state.checking.checkboxKey]);
      });
      areAllRowsChecked = true;
    }

    this.checkBoxes(checkedRows, areAllRowsChecked);
  }

  checkBoxes(checkedRows, areAllRowsChecked) {
    if (typeof this.props.checkboxesHandler === 'function') {
      this.props.checkboxesHandler(checkedRows);
    }

    this.setState({ checking: { checkedRows, areAllRowsChecked, checkboxKey: this.state.checking.checkboxKey } });
  }

  filteringHandler(column, value) {
    const filtering = { ...this.state.filtering };

    filtering[column] = value;

    this.setState({ filtering, rows: this.filter(this.props.rows, filtering) });
  }

  paginationHandler(newPage) {
    const pagination = { ...this.state.pagination };

    pagination.page = newPage;

    this.setState({ pagination })
  }

  sortingHandler(column) {
    this.setState({
      sorting: {
        column,
        reversed: this.state.sorting.column === column ? !this.state.sorting.reversed : false
      }
    });
  }

  sort(rows) {
    if (!this.props.withSorting || !this.state.sorting.column) {
      return rows;
    }

    const sortedRows = rows.sort((first, second) => {
      const firstVal = this.cellValueResolver(first, this.state.sorting.column);
      const secondVal = this.cellValueResolver(second, this.state.sorting.column);

      if (firstVal === secondVal) {
        return 0;
      }

      return firstVal < secondVal ? -1 : 1;
    });

    if (this.state.sorting.reversed) {
      return sortedRows.reverse();
    }

    return sortedRows;
  }

  filter(rows, filtering) {
    if (!this.props.withFiltering) {
      return rows;
    }

    if (Object.keys(filtering).length > 0) {
      rows = this.applyFiltering(rows, filtering);
    }

    if (typeof this.props.onFilter === 'function') {
      setTimeout(() => {
        this.props.onFilter(
          this.props.onFilterProvideColumn ? rows.map(row => row[this.props.onFilterProvideColumn]) : rows.length
        );
      }, 0);
    }

    return rows;
  }

  paginate(rows) {
    if (!this.props.withPagination) {
      return rows;
    }

    return rows.slice(
      (this.state.pagination.page - 1) * this.state.pagination.perPage,
      this.state.pagination.page * this.state.pagination.perPage
    );
  }

  applyFiltering(rows, filtering) {
    const columns = Object.keys(filtering);

    return rows.filter(row => {
      return columns.reduce((acc, column) => {
        const val = this.cellValueResolver(row, column);
        if (filtering[column].length === 0) {
          return acc;
        }

        if (val === null) {
          return false;
        }

        const datesFilter = filtering[column].match(/^(<|>|=|<=|>=|<>)([0-9]{4}-[0-9]{2}-[0-9]{2})$/);
        if (datesFilter !== null) {
          return acc && this.filterByDate(datesFilter[1], datesFilter[2], val);
        }

        if (filtering[column].indexOf('^') === 0) {
          return acc && String(val).indexOf(filtering[column].substring(1)) === 0;
        }

        if (filtering[column].indexOf('$') === filtering[column].length - 1) {
          const constraintLength = filtering[column].length - 1;

          return acc && String(val).indexOf(filtering[column].substring(0, constraintLength))
            === String(val).length - constraintLength;
        }

        return acc && String(val).toLowerCase().indexOf(filtering[column].toLowerCase()) !== -1;
      }, true);
    });
  }

  filterByDate(modifier, filterDateStr, inputDateStr) {
    const filterDate = new Date(filterDateStr);
    const rowDate = new Date(inputDateStr);

    switch (modifier) {
      case '<':
        return rowDate < filterDate;
      case '<=':
        return rowDate <= filterDate;
      case '>':
        return rowDate > filterDate;
      case '>=':
        return rowDate >= filterDate;
      case '<>':
        return rowDate.getTime() !== filterDate.getTime();
      case '=':
        return rowDate.getTime() === filterDate.getTime();
      default:
        return false;
    }
  };

  getSelectableRows() {
    return this.state.rows.filter(row => row.isSelectable !== false);
  }

  cellValueResolver(row, column) {
    return this.props.cellValueResolver ? this.props.cellValueResolver(row, column) : row[column];
  }

  provideColumns() {
    if (this.props.columnsMap) {
      return Object.keys(this.props.columnsMap);
    }

    if (Array.isArray(this.props.columns)) {
      return this.props.columns;
    }

    if (typeof this.props.columns === 'object' && this.props.columns !== null) {
      return Object.keys(this.props.columns);
    }

    return Object.keys(this.props.rows[0] || {});
  }

  cellWrapper(row, column) {
    const value = this.cellValueResolver(row, column);

    return this.props.cellWrapper ? this.props.cellWrapper(row, column, value) : value;
  }

  render() {
    const rows = this.sort(this.state.rows);

    return <RowContext.Provider value={ {
      highlight: this.props.highlight,
      withCheckboxes: this.props.withCheckboxes,
      checking: this.state.checking,
      columns: this.provideColumns(),
      actions: this.props.actions,
      actionsAlignment: this.props.actionsAlignment,
      rowClickHandler: this.props.onRowClick,
      rowTogglingHandler: this.rowTogglingHandler.bind(this),
      cellWrapper: this.cellWrapper.bind(this)
    } }>
      {
        this.props.withPagination
          ? <PaginationControl
            total={ rows.length }
            current={ this.state.pagination.page }
            perPage={ this.state.pagination.perPage }
            onPaginate={ this.paginationHandler.bind(this) }/>
          : null
      }
      <div className={ classes.tableContainer } style={ this.props.customContainerStyle }>
        <table className={ classes.table } id={ this.props.tableId ?? null } style={ this.props.customTableStyle }>
          <TableHead
            tableId={ this.props.tableId }
            areAllRowsChecked={ this.state.checking.areAllRowsChecked }
            columns={ this.provideColumns() }
            sorting={ this.state.sorting }
            filtering={ this.state.filtering }
            hasActions={ !!this.props.actions }
            onFilter={ this.filteringHandler.bind(this) }
            onSort={ this.sortingHandler.bind(this) }
            onToggleAll={ this.allRowsTogglingHandler.bind(this) }
            columnsMap={ this.props.columnsMap }
            withFiltering={ this.props.withFiltering }
            withSorting={ this.props.withSorting }
            withCheckboxes={ this.props.withCheckboxes }/>
          {
            Array.isArray(this.props.fixedRows) ? <TableBody key="fixed" rows={ this.props.fixedRows }/> : null
          }
          <TableBody key="data" rows={ this.paginate(rows) }/>
        </table>
      </div>
      {
        this.props.withPagination
          ? <PaginationControl
            total={ rows.length }
            current={ this.state.pagination.page }
            perPage={ this.state.pagination.perPage }
            onPaginate={ this.paginationHandler.bind(this) }/>
          : null
      }
    </RowContext.Provider>
  }
}

LiteTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.string), PropTypes.object ]),
  fixedRows: PropTypes.arrayOf(PropTypes.object),
  tableId: PropTypes.string,
  onRowClick: PropTypes.func,
  onFilter: PropTypes.func,
  checkboxesHandler: PropTypes.func,
  highlight: PropTypes.object,
  withSorting: PropTypes.bool,
  withFiltering: PropTypes.bool,
  withCheckboxes: PropTypes.bool,
  withPagination: PropTypes.bool,
  customContainerStyle: PropTypes.object,
  customTableStyle: PropTypes.object,
  actionsAlignment: PropTypes.oneOf(Object.values(AlignmentEnum)),
  onFilterProvideColumn: PropTypes.string,
  columnsMap: PropTypes.object,
  actions: PropTypes.func,
  cellWrapper: PropTypes.func,
  cellValueResolver: PropTypes.func,
  initialSorting: PropTypes.object,
  initialPagination: PropTypes.object,
  initialFiltering: PropTypes.object,
  initialChecking: PropTypes.array,
  checkboxKey: PropTypes.string,
};

export default LiteTable;
