import React, { Fragment, useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import classes from './FileUploadControl.module.css';

const FileUploadControl = props => {
  const [ uploadLabel, setUploadLabel ] = useState('Choose a file');

  return <Fragment>
    <Form.File.Label
      for={ props.id }
      className={ 'custom-file-label ' + classes.label }>
      { uploadLabel }
    </Form.File.Label>
    <Form.File
      name={ props.name }
      id={ props.id }
      onChange={ event => {
        setUploadLabel(event.currentTarget.files[0].name);
        props.changeHandler(event.currentTarget.files[0]);
      } }
      isInvalid={ props.isInvalid }
      isValid={ props.isValid }
      custom/>
  </Fragment>;
}

FileUploadControl.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  changeHandler: PropTypes.func,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool
};

export default FileUploadControl;
