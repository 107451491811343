import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import CheckCell from '../CheckCell/CheckCell';

import classes from './TableHead.module.css';

const TableHead = props => {
  return <thead>
  <tr>
    {
      props.withCheckboxes
        ? <CheckCell isHeader tableId={ props.tableId } onToggle={ props.onToggleAll } isChecked={ props.areAllChecked }/>
        : null
    }
    {
      props.columns.map((column, index) =>
        <td className={ classes.tableTitle } key={ `head-${ index }` }>
          <div onClick={ props.withSorting ? () => props.onSort(column) : null }
               className={ props.withSorting ? classes.titleSortable : '' }>
            { props.columnsMap ? props.columnsMap[column] : column }
            {
              props.withSorting && props.sorting.column === column
                ? <FontAwesomeIcon
                  className={ classes.sortingIcon }
                  icon={ props.sorting.reversed ? faSortDown : faSortUp }/>
                : null
            }
          </div>
          {
            props.withFiltering
              ? <div>
                <input className={ classes.filterInput }
                       onChange={ e => props.onFilter(column, e.target.value) }
                       value={ props.filtering[column] || '' }/>
              </div>
              : null
          }
        </td>
      )
    }
    {
      props.hasActions ? <td key="actions" className={ classes.tableTitle }>Actions</td> : null
    }
  </tr>
  </thead>
};

TableHead.propTypes = {
  filtering: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  sorting: PropTypes.object.isRequired,
  areAllRowsChecked: PropTypes.bool.isRequired,
  onToggleAll: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  tableId: PropTypes.string,
  withCheckboxes: PropTypes.bool,
  withSorting: PropTypes.bool,
  withFiltering: PropTypes.bool,
  hasActions: PropTypes.bool,
  columnsMap: PropTypes.object,
};

export default TableHead;
