import React from 'react';
import PropTypes from 'prop-types';

import CheckCell from '../CheckCell/CheckCell';
import RowContext from '../RowContext/RowContext';

import classes from './Row.module.css';

const Row = props => <RowContext.Consumer>
  { ({
       rowClickHandler, highlight, withCheckboxes, rowTogglingHandler, columns, actions,
       actionsAlignment, cellWrapper, checking
     }) => {
    let clickHandler = null;
    if (typeof rowClickHandler === 'function') {
      clickHandler = () => rowClickHandler(props.row);
    } else if (withCheckboxes && typeof rowTogglingHandler === 'function' && props.row.isSelectable !== false) {
      clickHandler = () => rowTogglingHandler(props.row[checking.checkboxKey]);
    }
    const clickableClass = clickHandler ? (' ' + classes.clickableRow) : '';
    const highlightedClass = highlight && props.row[highlight.field] === highlight.value ? ' ' + classes.tableRowHighlighted : '';

    return <tr
      className={ classes.tableRow + clickableClass + highlightedClass }
      style={ props.row.customStyle ?? null }
      onClick={ clickHandler }>
      {
        withCheckboxes
          ? <CheckCell
            onToggle={ () => rowTogglingHandler(props.row[checking.checkboxKey]) }
            value={ props.row[checking.checkboxKey] }
            isChecked={ checking.checkedRows.indexOf(props.row[checking.checkboxKey]) !== -1 }
            isDisabled={ props.row.isSelectable === false }/>
          : null
      }
      {
        columns.map((col, idx) => <td className={ classes.tableCell } key={ `data-${ idx }` }>
          { cellWrapper(props.row, col) }
        </td>)
      }
      {
        actions
          ? <td className={ classes.tableCell } key="actions" onClick={ e => e.stopPropagation() }>
            <div className={ classes.actionsCell } style={ { justifyContent: actionsAlignment } }>
              { actions(props.row) }
            </div>
          </td>
          : null
      }
    </tr>
  }
  }
</RowContext.Consumer>;

Row.propTypes = {
  row: PropTypes.object.isRequired
};

export default Row;
