import React from 'react';
import PropTypes from 'prop-types';

import classes from './CheckCell.module.css';

const CheckCell = props => <td className={ props.isHeader ? classes.headerCell : classes.dataCell } key="check-cell">
  {
    props.isDisabled
      ? null
      : <input
        id={ props.isHeader ? `liteTableSelectAllCheckbox${ props.tableId ?? ''}` : null }
        type="checkbox"
        value={ props.value }
        onChange={ props.onToggle }
        checked={ props.isChecked }/>
  }
</td>;

CheckCell.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  isChecked: PropTypes.bool,
  isHeader: PropTypes.bool,
  tableId: PropTypes.string
};

export default CheckCell;
