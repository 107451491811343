import React, { Component, createContext } from 'react';

const LoaderContext = createContext({
  isVisible: false,
  loaderShownAt: null,
  props: {},
  showLoader: () => {},
  updateProgress: () => {},
  hideLoader: () => {}
});

export class LoaderProvider extends Component {
  state = {
    loaderShownAt: null,
    isVisible: false,
    props: {},
    showLoader: this.showLoader.bind(this),
    hideLoader: this.hideLoader.bind(this),
    updateProgress: this.updateProgress.bind(this),
  };

  showLoader(props = {}) {
    this.setState({ isVisible: true, props, loaderShownAt: Date.now() });
  }

  hideLoader() {
    if (!this.state.isVisible) {
      return;
    }

    const timeConsumed = Date.now() - this.state.loaderShownAt;
    if (timeConsumed < 1000) {
      setTimeout(() => {
        this.setState({ isVisible: false, props: {}, loaderShownAt: null });
      }, 1000 - timeConsumed);
      return;
    }

    this.setState({ isVisible: false, props: {}, loaderShownAt: null });
  }

  updateProgress(progress) {
    this.setState({...this.state, props: { progress }})
  }

  render() {
    return (
      <LoaderContext.Provider value={ this.state }>
        { this.props.children }
      </LoaderContext.Provider>
    );
  }
}

export const LoaderConsumer = LoaderContext.Consumer;
