import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

import { NotificationsConsumer } from '../Notifications/NotificationsContext';

function withNotifications(Component) {
  const displayName = `withNotifications(${ Component.displayName || Component.name })`;
  const WrappedComponent = props => {
    const { wrappedComponentRef, ...remainingProps } = props;

    return (
      <NotificationsConsumer>
        {
          ({ notify, processError, drop, clearNotifications }) => {
            return (
              <Component
                { ...remainingProps }
                notify={ notify }
                clearNotifications={ clearNotifications }
                processError={ processError }
                drop={ drop }
                ref={ wrappedComponentRef }
              />
            );
          }
        }
      </NotificationsConsumer>
    );
  };

  WrappedComponent.displayName = displayName;
  WrappedComponent.WrappedComponent = Component;

  return hoistStatics(WrappedComponent, Component);
}

export default withNotifications;
