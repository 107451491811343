import React from 'react';
import { PageItem, Pagination } from 'react-bootstrap';
import PropTypes from 'prop-types';

const PaginationControl = props => {
  const pages = Math.ceil(props.total / props.perPage);
  const buttonsNumber = 9;
  const buildItem = page => {
    const isActive = page === props.current;

    return <PageItem
      key={ page }
      onClick={ isActive ? null : () => props.onPaginate(page) }
      active={ isActive }>
      { page }
    </PageItem>
  };

  const iteratePages = (min, max) => {
    const pageItems = [];
    for (let i = min; i <= max; i++) {
      pageItems.push(buildItem(i));
    }

    return pageItems;
  };

  let pageItems;
  if (pages < buttonsNumber) {
    pageItems = iteratePages(1, pages);
  } else {
    const min = Math.max(1, props.current - 2);
    const max = Math.min(pages, props.current + 2);

    pageItems = iteratePages(min, max);

    if (min === 1 || min === 2) {
      if (min === 2) {
        pageItems.unshift(buildItem(1));
      }
      pageItems.push(<Pagination.Ellipsis key="e1" disabled={ true }/>);

      while (pageItems.length < buttonsNumber) {
        pageItems.push(buildItem(pages - buttonsNumber + pageItems.length + 1));
      }
    } else if (max === pages || max === pages - 1) {
      if (max === pages - 1) {
        pageItems.push(buildItem(pages));
      }
      pageItems.unshift(<Pagination.Ellipsis key="e2" disabled={ true }/>);

      while (pageItems.length < buttonsNumber) {
        pageItems.unshift(buildItem(buttonsNumber - pageItems.length));
      }
    } else {
      pageItems.unshift(<Pagination.Ellipsis key="e1" disabled={ true }/>);
      pageItems.unshift(buildItem(1));
      pageItems.push(<Pagination.Ellipsis key="e2" disabled={ true }/>);
      pageItems.push(buildItem(pages));
    }
  }

  return <Pagination>{ pageItems }</Pagination>;
};

PaginationControl.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  onPaginate: PropTypes.func.isRequired
};

export default PaginationControl;
