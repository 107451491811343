import React from 'react';

import { LoaderConsumer } from './LoaderContext';
import Loader from './Loader';

const LoaderRoot = () => (
  <LoaderConsumer>
    { ({ isVisible, props }) =>
      isVisible ? <Loader { ...props } /> : null
    }
  </LoaderConsumer>
);

export default LoaderRoot;
