import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

import { ModalConsumer } from '../Modal/ModalContext';

function withModals(Component) {
  const displayName = `withModals(${ Component.displayName || Component.name })`;
  const WrappedComponent = props => {
    const { wrappedComponentRef, ...remainingProps } = props;

    return (
      <ModalConsumer>
        {
          ({ showModal, hideModal }) => {
          return (
            <Component
              { ...remainingProps }
              showModal={ showModal }
              hideModal={ hideModal }
              ref={ wrappedComponentRef }
            />
          );
        }
        }
      </ModalConsumer>
    );
  };

  WrappedComponent.displayName = displayName;
  WrappedComponent.WrappedComponent = Component;

  return hoistStatics(WrappedComponent, Component);
}

export default withModals;
