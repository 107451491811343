export class Notification {
  ttlSec;
  severity;
  header;
  body;
  dismissable;

  constructor(severity, body, ttlSec = 0, dismissable = true, header = null) {
    this.severity = severity;
    this.header = header;
    this.body = body;
    this.dismissable = dismissable;
    this.date = new Date();
    this.ttlSec = ttlSec;
  }

  isSimilar(notification) {
    return notification.severity === this.severity
      && notification.body === this.body
      && notification.header === this.header
      && notification.ttlSec === this.ttlSec
      && notification.dismissable === this.dismissable;
  }
}

export class NotificationSeverity {
  static SUCCESS = 'success';
  static ERROR = 'danger';
  static WARNING = 'warning';
  static INFO = 'info';
}
