import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import PropTypes from 'prop-types';

import classes from './Loader.module.css';
import Backdrop from '../Backdrop/Backdrop';
import Spinner from '../Spinner/Spinner';

export class LoaderEnum {
  static PROGRESS = 'PROGRESS';
  static NORMAL = 'NORMAL';
  static NONE = 'NONE';
}

class Loader extends Component {
  render() {
    return <div className={ classes.loaderContainer }>
      <Backdrop/>
      {
        this.props.progress >= 0
          ? <div className={ classes.progressBarContainer }>
            <div className={ classes.progressBarLabel }>{ this.props.progress + '%' }</div>
            <ProgressBar className={ classes.progressBar } now={ this.props.progress }/>
          </div>
          : <Spinner/>
      }
    </div>
  }
}

Loader.propTypes = {
  progress: PropTypes.string
};

export default Loader;
