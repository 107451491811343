import React from 'react';
import Alert from 'react-bootstrap/Alert';

import { NotificationsConsumer } from './NotificationsContext';

const NotificationsRoot = () => (
  <NotificationsConsumer>
    {
      ({ notifications, drop }) => notifications.map((notification, index) => {
        if (notification.ttlSec) {
          setTimeout(() => drop(notification), notification.ttlSec * 1000);
        }

        return <Alert
          key={ index }
          variant={ notification.severity }
          onClose={ () => drop(notification) }
          dismissible={ notification.dismissable }>
          {
            notification.header
              ? <Alert.Heading>{ notification.header }</Alert.Heading>
              : null
          }
          <p>{ notification.body }</p>
        </Alert>
      })
    }
  </NotificationsConsumer>
);

export default NotificationsRoot;
