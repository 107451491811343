import React from 'react';
import PropTypes from 'prop-types';

import Row from '../Row/Row';

const TableBody = props => <tbody>
{ props.rows.map((row, index) => <Row key={ `Row-${ index }` } row={ row }/>) }
</tbody>;

TableBody.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TableBody;
