import { createContext } from 'react';

import AlignmentEnum from '../AlignmentEnum';

const RowContext = createContext({
  rowClickHandler: () => {},
  rowTogglingHandler: () => {},
  cellWrapper: () => {},
  highlight: null,
  withCheckboxes: false,
  columns: [],
  checking: null,
  actions: null,
  actionsAlignment: AlignmentEnum.CENTER,
});

export default RowContext;
