import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import Modal from '../Modal';

const ConfirmationModal = props => <Modal
  title={ props.question }
  size={ props.size }
  actions={
    <Fragment>
      <Button variant='secondary' onClick={ props.hideModal }>No</Button>
      <Button variant='danger' onClick={ () => props.onYes(props.data) }>Yes</Button>
    </Fragment>
  }
  hideModal={ props.hideModal }>
  { props.explanation }
</Modal>;

ConfirmationModal.propTypes = {
  question: PropTypes.string.isRequired,
  onYes: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  explanation: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  data: PropTypes.any,
  size: PropTypes.oneOf([ 'sm', 'default', 'lg', 'xl' ])
};

export default ConfirmationModal;
