import BsModal from 'react-bootstrap/Modal';
import React from 'react';
import PropTypes from 'prop-types';

const Modal = props => <BsModal
  id={ props.id }
  show
  size={ props.size || 'default' }
  onHide={ props.hideModal }>
  <BsModal.Header closeButton>
    <BsModal.Title>{ props.title }</BsModal.Title>
  </BsModal.Header>
  { props.children ? <BsModal.Body>{ props.children }</BsModal.Body> : null }
  { props.actions ? <BsModal.Footer>{ props.actions }</BsModal.Footer> : null }
</BsModal>;

Modal.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.element, PropTypes.string ]).isRequired,
  hideModal: PropTypes.func.isRequired,
  actions: PropTypes.element,
  size: PropTypes.oneOf([ 'sm', 'default', 'lg', 'xl' ]),
  id: PropTypes.string
};

export default Modal;
