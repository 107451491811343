import React, { Component, createContext } from 'react';
import { Notification, NotificationSeverity } from './Notification';

const NotificationsContext = createContext({
  notifications: [],
  notify: () => {},
  drop: () => {},
  clearNotifications: () => {},
  processError: () => {}
});

export class NotificationsProvider extends Component {
  state = {
    notifications: [],
    notify: this.notify.bind(this),
    clearNotifications: this.clearNotifications.bind(this),
    processError: this.processError.bind(this),
    drop: this.drop.bind(this)
  };

  clearNotifications() {
    this.setState({ notifications: [] });
  }

  notify(severity, body, ttlSec = 0, dismissable = true, header = null) {
    const newNotification = new Notification(severity, body, ttlSec, dismissable, header);
    let newState = { ...this.state };
    newState.notifications.forEach(notification => {
      if (notification.isSimilar(newNotification)) {
        newState = this.dropFromState(newState, notification);
      }
    });

    const notifications = [ ...newState.notifications ];
    notifications.push(newNotification);

    newState.notifications = notifications;

    this.setState(newState);
  }

  drop(notificationToDrop) {
    this.setState(this.dropFromState(this.state, notificationToDrop));
  }

  dropFromState(state, notificationToDrop) {
    const newState = { ...state };
    const notificationIndex = newState.notifications.findIndex(notification => notification === notificationToDrop);

    if (notificationIndex === -1) {
      return state;
    }

    delete newState.notifications[notificationIndex];
    newState.notifications = newState.notifications.filter(_ => _ !== undefined);

    return newState;
  }

  processError(e) {
    let errorMessage = 'Something went wrong, please try again later.';
    if (e.response && e.response.data && e.response.data.error) {
      errorMessage = e.response.data.error;
    }

    this.notify(NotificationSeverity.ERROR, errorMessage, 15);
  }

  render() {
    return (
      <NotificationsContext.Provider value={ this.state }>
        { this.props.children }
      </NotificationsContext.Provider>
    );
  }
}

export const NotificationsConsumer = NotificationsContext.Consumer;
