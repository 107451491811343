import React, { Component, createContext } from 'react';

const ModalContext = createContext({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {},
  onHideCallback: null
});

export class ModalProvider extends Component {
  state = {
    component: null,
    props: {},
    showModal: this.showModal.bind(this),
    hideModal: this.hideModal.bind(this),
    onHideCallback: null
  };

  showModal(component, props = {}, onHideCallback = null) {
    this.setState({ component, props, onHideCallback });
  }

  hideModal () {
    if (this.state.onHideCallback) {
      this.state.onHideCallback();
    }

    this.setState({ component: null, props: {}, onHideCallback: null });
  }

  render() {
    return (
      <ModalContext.Provider value={ this.state }>
        { this.props.children }
      </ModalContext.Provider>
    );
  }
}

export const ModalConsumer = ModalContext.Consumer;
